




























import { Component, Vue, Prop } from "vue-property-decorator";
import LayoutMenuModule from "@/store/modules/layout-menu-module";
import usuarioModule from "@/store/modules/usuario-module";
import clienteModule from "@/store/modules/cliente-module";
import { Usuario } from "@/shared/dtos/usuario";

@Component({
  components: {
    UserProfile: () =>
      import("@/components/header/UserProfile/UserProfile.vue"),
    XBTN: () => import("@/components/buttons/x-btn.vue"),
    Notification: () =>
      import("@/components/header/Notification/notification.vue"),
  },
})
export default class Header extends Vue {
  @Prop() public title!: string;
  public get drawer(): boolean {
    return LayoutMenuModule.visible;
  }
  public set drawer(newtoggle: boolean) {
    LayoutMenuModule.setVisible(newtoggle);
  }

  public get usuario(): Usuario {
    clienteModule.getcliente(usuarioModule.mi_usuario.id_cliente);
    return usuarioModule.mi_usuario;
  }
  public get cliente() {
    return clienteModule.cliente.nombre;
  }
}
