import { BaseDto } from "@/shared/dtos/base-dto";

export class cliente extends BaseDto {
  public nombre!: string;
  public correo!: string;
  public telefono!: string;
  public datos_adicionales!: string;
  public licencia_inicio!: Date;
  public licencia_fin!: Date;
  public nombre_usuario!: string;
  public contrasena_usuario!: string;

}
