import { BaseDto } from "@/shared/dtos/base-dto";

export class MenuItem extends BaseDto {
    public icon!: string;
    public icon_fntaws!: string;
    public text!: string;
    public color!: string;
    public link!: string;
    public permission!: string;
    public children!:  MenuItem[];
    public id_padre!:number;
    public url_fija!:boolean;
    public orden!:number;
}
